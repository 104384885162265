import "@fontsource/roboto"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/300-italic.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"

import "./src/css/layout.css";
import "./src/css/temp-styles.css";

//import "./src/css/prism-ghcolors.css";
require(`katex/dist/katex.min.css`);